import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CrossCircleIcon from "../icons/CrossCircleIcon";
import { CustomSelect, CustomTextField, CustomAutoComplete, CustomFileInput } from "./CustomMUIInputs";
import FillButton from "./FillButton";
import "./partners-form-dialog.css";
import { CustomPhoneNumber } from "./CustomInputs";
import { useTheme } from "../contexts/ThemeContext";
import { toast } from "react-toastify";
import { validateInputs } from "../validator";
import { useState } from "react";

const PartnersFormDialog = ({open=true, onClose=()=>{}, onSubmit=()=>{}}) => {

    const { theme } = useTheme();
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrors({});
        const formData = new FormData(event.target);
        const formJson = Object.fromEntries(formData.entries());

        const {isValid, errors} = validateInputs(formJson, [{"name" : "first_name", "type" : "name"}, {"name" : "last_name", "type" : "name"}])
        if(!isValid){
            return setErrors(errors);
        }

        fetch("/become-a-partner", {
            headers: {
                'Content-Type' : "application/json"
            },
            method:"post",
            body:JSON.stringify(formJson)
        })
        .then(
            (response)=>{
                if(response.status === 200){
                    toast.success("Form Submitted", {closeOnClick:true});
                }else{
                    response.json().then(data=>{
                        console.log(data);
                    })
                }
            }
        )
        .catch(error=>{
            console.log(error);
        })
        .finally(()=>{
            onClose();
        })
    }

    return (
        <Dialog fullWidth open={open} onClose={onClose} 
            PaperProps={{
                style:{
                    borderRadius:"1rem", 
                    padding:"1rem",
                    width:"85%", maxWidth:"75rem", 
                    color : "var(--text-color)",
                    background: "var(--background-color)"
                },
                component:"form",
                onSubmit:handleSubmit
            }}
        >
            <DialogTitle
                className="partners-dialog-title"
            >
                <div className="title">What’s Stopping You from Partnering?</div>
                <p>Experience growth and success by partnering with Scan2Hire for cutting-edge recruitment solutions.</p>
                <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={onClose}>
                    <CrossCircleIcon color={"var(--text-color)"} style={{"opacity" : "0.75"}} height="25" width="25" />
                </div>
            </DialogTitle>
            <DialogContent
                className={`partners-dialog-content ${theme}`}
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center"
                }}
            >  
                <div className="input-row">
                    <CustomTextField 
                        id={"firstName"}
                        label={"First Name"}
                        name={"first_name"}
                        placeholder="Enter Your First Name"
                        required={true}
                        error={!!errors.first_name}
                        helperText={errors.first_name}
                    />  
                    <p></p>           
                    <CustomTextField 
                        id={"lastName"}
                        label={"Last Name"}
                        name={"last_name"}
                        error={!!errors.last_name}
                        helperText={errors.last_name}
                        placeholder="Enter Your Last Name"
                        required={true}
                    />             
                </div>

                <div className="input-row">
                    <CustomTextField 
                        id={"compnayName"}
                        name="company_name"
                        label={"Company Name"}
                        placeholder="Enter Company Name"
                        required={true}
                    />  
                    <p></p>           
                    <CustomPhoneNumber 
                        id={"phoneNumberDialog"}
                        name={"phone_number"}
                        label={"Phone Number"}
                        placeholder="Enter Phone Number"
                        className={"phone-number-input"}
                        defaultCountry={localStorage.getItem("country") || null}
                        required
                    />             
                    <p></p>           
                    <CustomTextField 
                        id={"EmailID "}
                        label={"Email ID"}
                        name={"email_id"}
                        placeholder="Enter Your Email ID"
                        type="email"
                        required={true}
                    />             
                </div>

                <div className="input-row">
                    <CustomSelect
                        options={["Social Media", "Google", "Website", "Reference", "Other"]}  
                        id={"source"}
                        name="source"
                        placeholder="Please Select"
                        label={"How did you hear about us?"}
                    />
                </div>
                
                <div className="input-row">
                    <CustomTextField
                        id={"reason"}
                        name="reason"
                        placeholder="Write Text Here"
                        label={"Why partner up with us?"}
                        multiline={true}
                        minRows={5} maxRows={5}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{display:"flex", justifyContent:"center", paddingTop:"0rem"}}>
                <FillButton
                    value={"Become a Partner"}
                    className={`partners-button ${theme}`} 
                    inverse={true}
                    onClick={onSubmit}
                />
            </DialogActions>
        </Dialog>
    )
}

export default PartnersFormDialog;
 